export function Headline(props: {
    children?: React.ReactNode;
    title: React.ReactNode;
    titleColored: React.ReactNode;
}) {
    return (
        <div>
            <h1 className="mb-2 flex max-w-3xl flex-wrap gap-2 text-5xl font-semibold text-white">
                {props.title}
                <span className="whitespace-normal bg-gradient-to-r from-green-400 to-teal-400 bg-clip-text text-transparent">
                    {props.titleColored}
                </span>
            </h1>
            {props.children && (
                <p className="max-w-xl text-white">{props.children}</p>
            )}
        </div>
    );
}
